import React, { useState, useCallback, useEffect } from "react";
import throttle from "lodash/throttle";
import { API } from "aws-amplify";

import "./MapWrapper.scss";

import { useAppContext } from "../../../Lib/UserContext";
import Map from "../../../Pages/Dashboard/Map.js";
import MapControls from "./MapControls";
import { getMapOrChartData, getAPIMethodByMapChartName } from "../../../Lib/Helpers/MapChartHelpers";

export default function MapWrapper(props) {
    const { stateLocation } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState({});
    const [mapData, setMapData] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [mapNameToMapInfo, setMapNameToMapInfo] = useState({});

    const getMapNameToMapInfo = useCallback(() => {
        const nameToFiltersMap = {};

        for (const key of Object.keys(dashboardData)) {
          for (const mapName of Object.keys(dashboardData[key])) {
            nameToFiltersMap[mapName] = dashboardData[key][mapName];
          }
        }

        return nameToFiltersMap;
    }, [dashboardData]);

    const getMapSourceElement = () => {
      if (!props.mapSource) {
        return "";
      }

      const sourceLink = /\(([^)]+)\)/g.exec(props.mapSource)?.[1] || "";
      return props.mapSource
        .replace("[", `<a href="${sourceLink || ""}" target="_blank">`)
        .replace("]", "</a>")
        .replace(/\((.*)\)/g, "");
    };

    useEffect(() => {
        if (!props.mapName) {
            return;
        }

        const { dashboardData, defaultFilterArray } = getMapOrChartData(stateLocation);

        setDashboardData(dashboardData);
        setSelectedFilters(defaultFilterArray);
        setMapNameToMapInfo(getMapNameToMapInfo());
    }, [props.mapName, stateLocation, getMapNameToMapInfo]);

    const callAPI = (method) => {
      API.post("lighthouse-data-initiative", method, {
        body: {
          filters: selectedFilters,
          stateLocation
        }
      }).then((response) => {
        setMapData(response);
        setIsLoading(false);
      }).catch((error) => {
        console.log("Error on api call to get map data", method, error);
      });
    };

    const throttleCallAPI = throttle(callAPI, 1000);

    useEffect(() => {
        if (!props.mapName) {
            return;
        }

        const apiMethodName = getAPIMethodByMapChartName(props.mapName, true);
        if (!apiMethodName) {
            return;
        }

        throttleCallAPI(apiMethodName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);
  
  const stateFilterCallback = (item) => {
    console.log('current filters ', selectedFilters)
    var newArray = [...selectedFilters];
    for (let i = 0; i < newArray.length; i++) {
      if (
        newArray[i].name === item.name &&
        newArray[i].filter === item.filter
      ) {
        newArray[i] = item
      }
    }
    console.log(item)
    setSelectedFilters(newArray);
  };

    return (
        <div className="public-site-map">
            <div className="public-site-map-wrapper">
                <MapControls
                    mapName={props.mapName}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
            mapNameToMapInfo={mapNameToMapInfo}
            mapData={mapData}
            stateFilterCallback={stateFilterCallback}
                />
                <div className="public-site-map-container">
                    {
                        isLoading
                            ? <div></div>
                            : <Map
                                mapZoom={3.6}
                                charts={mapNameToMapInfo[props.mapName].secondary}
                                data={mapData}
                                filters={selectedFilters}
                  shouldUseNewStyles={true}
                  filterCallback={stateFilterCallback}
                            />
                    }
                </div>
                <div className="public-site-map-description">
                    <div className="map-header">
                        {props.mapTitle}
                    </div>
                    <div className="map-description">
                        {props.mapInsight}
                    </div>
                </div>
            </div>
            <div
              className="public-site-map-footer"
              dangerouslySetInnerHTML={{ __html: getMapSourceElement() }}
            >
            </div>
        </div>
    )
}
