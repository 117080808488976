import React from "react";
import { API } from "aws-amplify";
import { availableDashboards } from '../../Data/availableDashboards'
import { allStateNames } from '../../Data/allStateNames'

import clsx from "clsx";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Button
} from '@material-ui/core'



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  cardContainer: {
    padding: "20px 30px",
    marginLeft: '72px'
  },
  primaryButton: {
    backgroundColor: "#28ADE3",
    borderRadius: "3px",
    color: "white",
    marginTop: "20px",
  },
  appBar: {
    width: `calc(100% - 75px)`,
    marginLeft: '75px',
    zIndex: 888
  },
  appBarOpen: {
    width: `calc(100% - 75px)`,
    marginLeft: "0px",
    background: "#fafafa",
    color: "black",
  },
  drawer: {
    width: 200,
    flexShrink: 0,
    background: "#fafafa",
  },
  drawerPaper: {
    width: 200,
    background: "#fafafa",
    border: "none",
    marginTop: "68px",
  },
  openIcon: {
    color: "#28ADE3",
  },
  toolbar: {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  content: {
    marginTop: "64px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  margin: {
    marginTop: "12px",
  },
}));

const Demographics = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [newUser, setNewUser] = React.useState({});

  const handleChange = (event) => {
    var newUserInfo = { ...newUser };
    newUserInfo[event.target.name] = event.target.value;
    setNewUser(newUserInfo);
    // console.log('here ', availableDashboards?.filter(d => d.stateLocation === newUserInfo.state_location))
  };

  const handleSubmit = () => {
    setLoading(true);
    API.post("lighthouse-data-initiative", "addnewuser", {
      body: newUser,
    })
      .then((response) => {
        setLoading(false);
        setSuccess(response);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setSuccess("Error creating new user.");
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <AppBar
          color="default"
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarOpen]: !props.open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap>
              Admin{" "}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.cardContainer}>
          <h2>Add New User</h2>
          <p style={{ fontWeight: "300" }}>
            Please input a user's email, location, and role type to add.
          </p>
          <FormControl fullWidth className={classes.margin}>
            <Typography className={classes.label}>
              User Email Address
            </Typography>

            <TextField
              inputProps={{ className: classes.root }}
              required
              fullWidth
              variant="outlined"
              placeholder="Email"
              name="new_user_email"
              onChange={handleChange}
            />
          </FormControl>

          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
            required
            inputProps={{ className: classes.root }}
          >
            <Typography className={classes.label}>Location</Typography>
            <Select
              labelId="usre-role-label"
              id="user-role"
              name="state_location"
              defaultValue=''
              value={newUser.state_location}
              style={{ textAlign: "left" }}
              onChange={handleChange}
              helpertext=""
            >
              <MenuItem value="">Select..</MenuItem>
              {allStateNames?.map((s, i) => (
                <MenuItem
                  key={i}
                  value={s.stateLocation}
                  disabled={!availableDashboards.find(d => d.stateLocation === s.stateLocation)} // Only available dashboards should be active to select
                >
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            className={classes.margin}
            variant="outlined"
            required
            inputProps={{ className: classes.root }}
          >
            <Typography className={classes.label}>User Role</Typography>
            <Select
              labelId="usre-role-label"
              id="user-role"
              name="user_role"
              defaultValue=''
              value={newUser.user_role}
              style={{ textAlign: "left" }}
              onChange={handleChange}
              helpertext=""
            >
              <MenuItem value=''>Select..</MenuItem>
              {availableDashboards?.filter(d => d.stateLocation === newUser?.state_location)?.map(obj => (
                obj.accessLevels?.map((level, i) => (
                  <MenuItem value={level.var}>{level.name}</MenuItem>
                ))

              ))
                // <MenuItem value="Admin">Admin</MenuItem>
                // <MenuItem value="OOG">Office of the Governor</MenuItem> // In previous accounts on the app
                // <MenuItem value="CC">Care Coordinator</MenuItem>  // In previous accounts on the app
                // <MenuItem value="Standard">Standard</MenuItem>
              }
            </Select>
          </FormControl>

          <Button className={classes.primaryButton} onClick={handleSubmit}>
            {loading === false ? "Add New User" : "Loading"}
          </Button>
          <div>{success}</div>
        </div>
      </main>
    </div>
  );
}


export default Demographics