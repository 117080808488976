import React from "react";
import { Switch } from "react-router-dom";

// import PersistentDrawerLeft from "./Components/LoggedInLayout";
import Login from "./Pages/Login/Login";
import NewUser from "./Pages/Login/NewUser";
import GeographicTrends from "./Pages/PublicSite/GeographicTrends";
import DataExplorer from "./Pages/PublicSite/DataExplorer";
import StatePolicy from "./Pages/PublicSite/StatePolicy";
import Partnerships from "./Pages/PublicSite/Partnerships";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import PrimaryNavigation from "./Components/PrimaryNavigation";

export default function Routes() {
  return (
    <Switch>
      <UnauthenticatedRoute path="/newuser">
        <NewUser />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/" exact>
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/geotrends" exact>
        <GeographicTrends />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/dataexplorer" exact>
        <DataExplorer />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/policy" exact>
        <StatePolicy />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/partners" exact>
        <Partnerships />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/dashboard" exact>
        <PrimaryNavigation />
      </AuthenticatedRoute>
      
      {/* <AuthenticatedRoute path="/dashboard/map" exact>
        <PersistentDrawerLeft />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/dashboard/demographics" exact>
        <PersistentDrawerLeft />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/dashboard/indicators" exact>
        <PersistentDrawerLeft />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/dashboard/care" exact>
        <PersistentDrawerLeft />
      </AuthenticatedRoute> */}
      <AuthenticatedRoute path="/reports" exact>
        <PrimaryNavigation />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/admin" exact>
        <PrimaryNavigation />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/home" exact>
        <PrimaryNavigation />
      </AuthenticatedRoute>
    </Switch>
  );
}
