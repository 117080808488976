import React from "react";

import "./ButtonLink.scss";

export default function ButtonLink(props) {
    return (
        <button
            type="button"
            className="clear-btn button-link"
            onClick={props.onClickFn}
        >
            {props.children}
        </button>
    );
};