export const mapNameToAPIMethod = {
    "Services": "getservicesmapdata",
    "Coordination": "getcaremapdata",
    "Screenings": "getscreeningmapdata",
    "TraffickingLocations": "gettraflocationsmapdata",
    "Federal": "getfederalcasesmapdata",
    "PublicSafety": "getpublicsafetymapdata",
    "TDCJ": "gettdcjmapdata",
    "Demand": "getdemandmapdata",
    "Hotline": "gethotlinemapdata",
    "IMB": "getimbmapdata",
    "Community": "getcommunityriskmapdata"
};

export const chartNameToAPIMethod = {
    "Services": {
      "LA": "getservicesladata",
      general: "getservicesdata"
    },
    "Coordination": {
      "LA": "getlacaredata",
      general: "getcaredata"
    },
    "Screenings": {
      "LA": "getscreeningdata",
      general: "getscreeningdata"
    },
    "TraffickingLocations": {
      "LA": "gettraflocationsdata",
      general: "gettraflocationsdata"
    },
    "Federal": {
      "LA": "getfederalcasesdata",
      general: "getfederalcasesdata"
    },
    "PublicSafety": {
      "LA": "getpublicsafetydata",
      general: "getpublicsafetydata"
    },
    "TDCJ": {
      "LA": "getservicesladata",
      general: "getservicesdata"
    },
    "Demand": {
      "LA": "getdemanddata",
      general: "getdemanddata"
    },
    "News": {
      "LA": "getnewsdata",
      general: "getnewsdata"
    },
    "Hotline": {
      "LA": "gethotlinedata",
      general: "gethotlinedata"
    },
    "IMB": {
      "LA": "getimbdata",
      general: "getimbdata"
    },
    "Community": {
      "LA": "getcommunityriskdata",
      general: "getcommunityriskdata"
    },
  };
