export const ContentfulFields = {
    geographicTrends: {
        ctaBody: "",
        ctaButton: "",
        ctaButtonLink: "",
        ctaTitle: "",
        header: "",
        headerBody: "",
        headerButton: "",
        headerButtonLink: "",
        headerMedia: "",
        introBodyColumns: "",
        introBodySingle: "",
        introTitle: "",
        mapBody: "",
        mapTitle: "",
    },
    dataExplorer: {
        header: "",
        headerBody: "",
        headerButton: "",
        headerButtonLink: "",
        selectionText: "",
        selection: "",
        chartTitle: "",
        chartBody: "",
        ctaTitle: "",
        ctaBody: "",
        ctaMedia: "",
        ctaButton: "",
        ctaButtonLink: "",
    },
    policiesLegislation: {
        header: "",
        headerBody: "",
        headerButton: "",
        headerButtonLink: "",
        headerMedia: "",
        introTitle: "",
        introBodyColumns: "",
        introBodySingle: "",
        timelineTitle: ""
    },
    tableItem: {
        icon: "",
        title: "",
        body: "",
        media: "",
        order: "",
        pageAssociated: ""
    },
    timelineItem: {
        year: "",
        title: "",
        body: "",
        pageAssociated: ""
    },
    partnerships: {
        header: "",
        headerBody: "",
        headerButton: "",
        headerButtonLink: "",
        headerMedia: "",
        introTitle: "",
        introBodyColumns: "",
        section1Title: "",
        section1Body: "",
        section2Title: "",
        section2Body: "",
        ctaTitle: "",
        ctaBody: "",
        ctaMedia: "",
        ctaButton: "",
        ctaButtonLink: "",
    },
    map: {
        mapName: "",
        mapTitle: "",
        mapInsight: "",
        mapSource: "",
        mapPageAssociated: ""
    },
    chart: {
        chartTitle: "",
        chartInsight: "",
        chartName: "",
        chartSource: "",
        chartPageAssociated: "",
        chartCategory: "",
        chartSelectionCategory: "",
        chartId: ""
    },
    footer: {
        footerText: ""
    },
    logoComponent: {
        logo: "",
        order: "",
        link: "",
        sectionNumber: ""
    },
    bannerBar: {
        bannerText: ""
    }
};