import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";

import "./NavigationHeader.scss";

export default function NavigationHeader(props) {
    const history = useHistory();
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);

    const isTabActive = (tabRoute) => {
        return tabRoute === window.location.pathname;
    };

    const getActiveTabName = (tabOptions) => {
        return tabOptions.find((tab) => isTabActive(tab.route))?.name;
    };

    const navigateToRoute = (e, route) => {
        e.preventDefault();
        history.push(route);
    };

    const toggleMenu = (e) => {
        e.preventDefault();
        setIsMenuExpanded(!isMenuExpanded);
    };

    return (
        <div ref={props.innerRef} className="navbar">
            <div className="navbar-button-group">
                <button
                    onClick={() => {
                        window.location.href = "https://www.alliesagainstslavery.org";
                    }}
                    className="clear-btn breadcrumb"
                >
                    <ArrowBackIcon />
                    <span>
                        Allies Against Slavery
                    </span>
                </button>
                <button
                    className="clear-btn menu-button"
                    onClick={toggleMenu}
                >
                    <MenuIcon />
                </button>
            </div>
            <div className="tab-group">
                <div className="active-tab-name">
                    {getActiveTabName(props.tabOptions)}
                </div>
                <div ref={props.innerOverlayRef} className={isMenuExpanded ? "tab-option-list expanded" : "tab-option-list"}>
                    {
                        props.tabOptions.map((tab, index) => {
                            return (
                                <button
                                    key={index}
                                    className={isTabActive(tab.route) ? 'clear-btn tab-option active' : 'clear-btn tab-option'}
                                    onClick={(e) => navigateToRoute(e, tab.route)}
                                >
                                    {tab.name}
                                    {isTabActive(tab.route) ? <div className="tab-option-border"></div> : <Fragment></Fragment>}
                                </button>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};