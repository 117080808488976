import React, { Fragment, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./CustomDropdown.scss";

export default function CustomDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        props.setSelectedValue(value);
    };

    const handleOnOpen = () => {
        setIsOpen(true);
    };

    const handleOnClose = () => {
        setIsOpen(false);
    }

    return (
        props.menuItems.length
            ? <div className={isOpen ? "custom-select-dropdown open" : "custom-select-dropdown"}>
                <FormControl>
                    <Select
                        value={props.selectedValue || props.menuItems[0]}
                        onChange={handleChange}
                        onOpen={handleOnOpen}
                        onClose={handleOnClose}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{
                            className: "custom-select-dropdown-menu"
                        }}
                    >
                    {
                        props.menuItems?.map((item, key) => (
                            <MenuItem
                                value={item.value}
                                key={key}
                            >
                                { item.text }
                            </MenuItem>
                        ))
                    }
                    </Select>
                </FormControl>
            </div>
            : <Fragment></Fragment>
    );
};