import React from "react";

import "./LogoSection.scss";

import LogoWrapper from "../LogoWrapper/LogoWrapper";

export default function LogoSection(props) {
    return (
        <div className="logo-section">
            <div className="logo-section-header">
                <div className="title">
                    {props.title}
                </div>
                <div className="description">
                    {props.description}
                </div>
            </div>
            <div className="logo-section-list">
                {
                    props.logoItems?.map((logo) => (
                        <LogoWrapper
                            link={logo?.link}
                            url={logo?.logo?.url}
                            title={logo?.logo?.title}
                        />
                    ))
                }
            </div>
        </div>
    );
}