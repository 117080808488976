import React, { useEffect } from "react";
import clsx from "clsx";
import { useAppContext } from "../../Lib/UserContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SampleChart from "../../Assets/Images/card2.png";
import Arrow from "../../Assets/Images/arrow.svg";
import MapIcon from "../../Assets/Images/map-1.svg";
import ReportGroup from "./ReportGroup";
import { API } from "aws-amplify";
import html2canvas from "html2canvas";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  primaryButton: {
    backgroundColor: "#28ADE3",
    borderRadius: "3px",
    color: "white",
    marginRight: "10px",
  },
  cardContainer: {
    padding: "20px 30px",
    marginLeft: '72px'
  },
  buttonWrapper: {
    position: "absolute",
    right: "10px",
  },
  appBar: {
    width: `calc(100% - 75px)`,
    marginLeft: '75px',
    zIndex: 888
  },
  appBarOpen: {
    width: `calc(100% - 75px)`,
    marginLeft: "0px",
    background: "#fafafa",
    color: "black",
  },
  openIcon: {
    color: "#28ADE3",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    marginTop: "64px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
  noreportimages: {
    display: "flex",
    alignItems: "flex-start",
  },
}));

export default function Reports(props) {
  const classes = useStyles();
  const [reports, setReports] = React.useState([]);
  const [dataStatus, setDataStatus] = React.useState(false);
  const [url, setUrl] = React.useState();
  const [generateReports, setGenerateReports] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { email, role, stateLocation } = useAppContext();
  const [reportLoading, setReportLoading] = React.useState(false);
  const [confirmClear, setConfirmClear] = React.useState(false);


  // const handleSourceOpen = () => {
  //   setOpenSource(true);
  // };

  const handleClearOpen = () => {
    setConfirmClear(true);
  };

  const handleClearClose = () => {
    setConfirmClear(false);
  };

  const handleConfirmClear = () => {
    setGenerateReports([]);
    setReports([]);
    props.callBackReports([]);
    API.post("lighthouse-data-initiative", "removereports", {
      body: {
        type: "All",
        email: email
      },
    })
      .then((response) => {
        console.log(response);
        console.log('reports: ', reports)
      })
      .catch((err) => {
        console.log(err);
      });
    setConfirmClear(false);
  };

  const handleUnsaveReport = (item) => {
    // console.log(item)
    API.post("lighthouse-data-initiative", "removereports", {
      body: {
        reportData: item,
        type: "Single",
        email: email
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveReport = (item) => {
    API.post("lighthouse-data-initiative", "removereports", {
      body: {
        reportData: item,
        type: "Single",
        email: email
      },
    })
      .then((response) => {
        // console.log(response);
        let allGeneratedReports = [...generateReports]
        let allReports = [...reports]
        var updateGenerateReports = allGeneratedReports.filter((el) => el.id !== item.id);
        var updateReports = allReports.filter((el) => el.id !== item.id);
        setGenerateReports(updateGenerateReports);
        setReports(updateReports);
        props.callBackReports(updateReports);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Run createPDFReport API
  const handleAddReport = async (checked, item, title) => {
    // console.log('checked: ', checked)
    if (checked === true) {
      // console.log(item);
      var newReports = [...generateReports];
      var svg = document.getElementById(item.id);
      console.log(item)
      var table = document.getElementById(`${item.id}-table`);
      if (table) {
        item.table = table.innerHTML;
      }
      setLoading(true);
    
      if (item.name.split(' ')[item.name.split(' ').length -1] === 'Map') {
        var canvas = await html2canvas(document.getElementById(item.id))
          const base64image = canvas.toDataURL("image/jpeg");
          // console.log(base64image)
        item.element = `<img src='${base64image}' width='900px' />`;
      } else {
        item.element = svg.innerHTML;
      }
      item.title = title;
      newReports.push(item);
      setGenerateReports(newReports);
      API.post("lighthouse-data-initiative", "createpdf", {
        body: {
          reportData: newReports,
        },
      }).then((response) => {
        // console.log(response)
        setUrl("data:application/octet-stream;base64," + response);
        setLoading(false);
      }).catch((err) => {
        API.post("lighthouse-data-initiative", "createpdf", {
          body: {
            reportData: newReports,
          },
        }).then((data) => {
          // console.log(data)
          setUrl("data:application/octet-stream;base64," + data);
          setLoading(false);
        }).catch((seconderr) => {
          setLoading(false);
          console.log('err ', seconderr)
          alert('An error occurred. Please try again later.')
      })
    }) } else {
      var filteredGenerateReports = generateReports.filter(
        (el) => el.id !== item.id
      );
      setGenerateReports(filteredGenerateReports);
      setLoading(true);
      API.post("lighthouse-data-initiative", "createpdf", {
        body: {
          reportData: filteredGenerateReports,
        },
      }).then((response) => {
        setUrl("data:application/octet-stream;base64," + response);
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log('err ', err)
      })
      
    }
  };

  const addSaveReports = (item, title) => {
    // console.log(title)
    API.post("lighthouse-data-initiative", "addsavedreports", {
      body: {
        reportData: item,
        title: title,
        email: email,
      },
    })
      .then((response) => {
        var filteredReports = reports.map((el) => {
          // if (item.config){
          //   if (el.id === item.id){
          //     el.config.saved = true;
          //     el.config.title = title;
          //   }
          //   return el.config;
          // } else {
          if (el.id === item.id) {
            el.saved = true;
            el.title = title;
          }
          return el;
          // }
        });
        // (filteredReports)
        setReports(filteredReports);
        props.callBackReports(filteredReports);
      })
      .catch((err) => {
        console.log(err);
        alert('Error saving report. Please try again later')
      });
  };

  //Each time a user clicks on add a report, a snapshot of that svg's innerHTML is sent along with the data about the svg itself. The user then select all of the reports, and these are sent to the backend


  useEffect(() => {
    setDataStatus(false)
    setReportLoading(true);
    
    async function fetchData() {
        for (let i = 0; i < props.data.length; i++) {
          if (props.data[i].name === 'Services Map') {
            let response = await API.post("lighthouse-data-initiative", "getservicesmapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Services' && stateLocation === 'TX') {
            let response = await API.post("lighthouse-data-initiative", "getservicesdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Services' && stateLocation === 'LA') {
            let response = await API.post("lighthouse-data-initiative", "getservicesladata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
              props.data[i].value = response
          } else if (props.data[i].name === 'Care Coordination Map') {
            let response = await API.post("lighthouse-data-initiative", "getcaremapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Care Coordination' && stateLocation === 'TX') {
            let response = await API.post("lighthouse-data-initiative", "getcaredata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Care Coordination' && stateLocation === 'LA') {
            let response = await API.post("lighthouse-data-initiative", "getlacaredata", {
              body: {
                filters: props.data[i].config,
              },
            })
            // console.log(response, 'RESPONSE')
            props.data[i].value = response;
          } else if (props.data[i].name === 'Screenings Map') {
            let response = await API.post("lighthouse-data-initiative", "getscreeningmapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Screenings') {
            let response = await API.post("lighthouse-data-initiative", "getscreeningdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response
          } else if (props.data[i].name === 'Locations Map') {
            let response = await API.post("lighthouse-data-initiative", "gettraflocationsmapdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Locations') {
            let response = await API.post("lighthouse-data-initiative", "gettraflocationsdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          }
          else if (props.data[i].name === 'Law Enforcement Map') {
            let response = await API.post("lighthouse-data-initiative", "getpublicsafetymapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Law Enforcement') {
            let response = await API.post("lighthouse-data-initiative", "getpublicsafetydata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'Federal Cases Map') {
            let response = await API.post("lighthouse-data-initiative", "getfederalcasesmapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Federal Cases') {
            let response = await API.post("lighthouse-data-initiative", "getfederalcasesdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'State Policy Map') {
            let response = await API.post("lighthouse-data-initiative", "getstatepolicymapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'State Policy') {
            let response = await API.post("lighthouse-data-initiative", "getstatepolicydata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation,
                states: props.data[i].activeCategory
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'Texas Department of Criminal Justice Map') {
            let response = await API.post("lighthouse-data-initiative", "gettdcjmapdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Texas Department of Criminal Justice') {
            let response = await API.post("lighthouse-data-initiative", "gettdcjdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'Demand Map') {
            let response = await API.post("lighthouse-data-initiative", "getdemandmapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Demand') {
            let response = await API.post("lighthouse-data-initiative", "getdemanddata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'News Map') {
            let response = await API.post("lighthouse-data-initiative", "getnewsdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'News') {
            let response = await API.post("lighthouse-data-initiative", "getnewsdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          }
          else if (props.data[i].name === 'Hotline Map') {
            let response = await API.post("lighthouse-data-initiative", "gethotlinemapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Hotline') {
            let response = await API.post("lighthouse-data-initiative", "gethotlinedata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            // Remove the word 'County' from county names
            response.casesByCounty = response.casesByCounty.map(obj => ({ county: obj.county.replace(' County', ''), total: obj.total }))
            response.casesByCountyAndPopulation = response.casesByCountyAndPopulation.map(obj => ({ county: obj.county.replace(' County', ''), total: obj.total }))
            props.data[i].value = response;
          }
          else if (props.data[i].name === 'Businesses of Interest Map') {
            let response = await API.post("lighthouse-data-initiative", "getimbmapdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Businesses of Interest') {
            let response = await API.post("lighthouse-data-initiative", "getimbdata", {
              body: {
                filters: props.data[i].config,
                stateLocation: stateLocation
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].name === 'Community Risk Map') {
            let response = await API.post("lighthouse-data-initiative", "getcommunityriskmapdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          } else if (props.data[i].type === 'Community Risk') {
            let response = await API.post("lighthouse-data-initiative", "getcommunityriskdata", {
              body: {
                filters: props.data[i].config,
              },
            })
            props.data[i].value = response;
          }
        }
      setReports(props.data);
      setDataStatus(true)
      setReportLoading(false);
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, stateLocation]);

  return (
    <div className={classes.root} id="report-wrapper">
      <CssBaseline />
      <main className={classes.content}>
        <AppBar
          color="default"
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarOpen]: !props.open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap>
              Reports
            </Typography>
            {/* <IconButton color="inherit" onClick={handleSourceOpen}>
              <OpenInNewIcon className={classes.openIcon} alt="Date Sources" />
            </IconButton> */}
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.primaryButton}
                href={url}
                download="LighthouseReport.pdf"
                disabled={loading}
              >
                {loading ? "Generating Report..." : `Download Report`}
              </Button>
              <Button onClick={handleClearOpen} variant="outlined">
                Delete All Reports
              </Button>
            </div>
          </Toolbar>
        </AppBar>

        {reports.length > 0 && !reportLoading ? (
          <div style={{ padding: "20px" }} className={classes.cardContainer}>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={MapIcon} alt="Map" width="20" />
                <h2 style={{ marginLeft: "10px" }}>Prevention & Intervention</h2>
              </div>
              <ReportGroup
                type="Services"
                data={reports}
                addReport={handleAddReport}
                removeReports={handleRemoveReport}
                saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                dataStatus={dataStatus}
                loading={loading}
              />
              {role === "Standard" ? null : (
                <ReportGroup
                  type="Care Coordination"
                  data={reports}
                  addReport={handleAddReport}
                  removeReports={handleRemoveReport}
                  saveReports={addSaveReports}
                  handleUnsaveReport={handleUnsaveReport}
                  loading={loading}
                />
              )}
              <ReportGroup
                type="Screenings"
                data={reports}
                addReport={handleAddReport}
                removeReports={handleRemoveReport}
                saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
                
              />
              {stateLocation === 'LA' ? (
                <ReportGroup
                  type="Locations"
                  data={reports}
                  addReport={handleAddReport}
                  removeReports={handleRemoveReport}
                  saveReports={addSaveReports}
                  handleUnsaveReport={handleUnsaveReport}
                  loading={loading}
                  
                />
              ) : null}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={MapIcon} alt="Map" width="20" />
                    <h2 style={{ marginLeft: "10px" }}>Incidents & Cases</h2>
                  </div>
                  <ReportGroup
                    type="Law Enforcement"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
                  />
                  <ReportGroup
                    type="Federal Cases"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
                  />
                  <ReportGroup
                    type="State Policy"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                    handleUnsaveReport={handleUnsaveReport}
                    loading={loading}
                  />
                  <ReportGroup
                    type="Demand"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
                  />
                {stateLocation === 'TX' ?
                  <div>
                  <ReportGroup
                    type="Texas Department of Criminal Justice"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                    handleUnsaveReport={handleUnsaveReport}
                    loading={loading}
                  />
                  
                  <ReportGroup
                    type="News"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                    handleUnsaveReport={handleUnsaveReport}
                    loading={loading}
                  />
                </div> : null}
              
              <ReportGroup
                type="Hotline"
                data={reports}
                addReport={handleAddReport}
                removeReports={handleRemoveReport}
                saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
              />
              
                  <ReportGroup
                    type="Businesses of Interest"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                handleUnsaveReport={handleUnsaveReport}
                loading={loading}
              />
              {/* ONLY FOR TEMPORARY COMMUNITY RISK DASHBOARD VIEWING */}
                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={MapIcon} alt="Map" width="20" />
                    <h2 style={{ marginLeft: "10px" }}>Risk & Resiliency</h2>
                  </div>
                  <ReportGroup
                    type="Community Risk"
                    data={reports}
                    addReport={handleAddReport}
                    removeReports={handleRemoveReport}
                    saveReports={addSaveReports}
                    handleUnsaveReport={handleUnsaveReport}
                    loading={loading}
                  /> */}
            </div>
          </div>
        ) : null}

        {reports.length === 0 && !reportLoading ? (
          <div className={classes.cardContainer}>
            <h2>No reports added</h2>
            <p style={{ fontWeight: "300" }}>
              To add a report, click the 'Add To Reports' icon located in the
              top-right of cards found throughout the Lighthouse platform, as
              seen below. From there reports can be managed and exported.
            </p>
            <div className={classes.noreportimages}>
              <img src={SampleChart} alt="Sample Chart" />
              <img src={Arrow} alt="Arrow Indicator" />
            </div>
          </div>
        ) : null}
      </main>
      <Dialog
        open={reportLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2>Updating Reports...</h2>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmClear}
        onClose={handleClearClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete all reports?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClearClose} >
            No, return to reports
          </Button>
          <Button onClick={handleConfirmClear} color="secondary" autoFocus>
            Yes, delete all
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
