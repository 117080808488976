export const ENUMS = {
    URLS: {
        TWITTER: "https://x.com/i/flow/login?redirect_after_login=%2Falliesatx",
        FACEBOOK: "https://www.facebook.com/allies.atx",
        INSTAGRAM: "https://www.instagram.com/alliesagainstslavery/",
        LINKED_IN: "https://www.linkedin.com/company/3977653",
        COOKIES_SETTINGS: "",
        NOTICES: "https://www.alliesagainstslavery.org/notices",
        PRIVACY_POLICY: "https://cdn.prod.website-files.com/629e4c5e68ca04db98357e6e/63366559684b94ac945d9441_allies-privacy-policy.pdf",
        TERMS: "https://cdn.prod.website-files.com/629e4c5e68ca04db98357e6e/633665575add9aaca4c203c5_new-allies-terms-of-use.pdf"
    },
    HUBSPOT: {
        SUBMIT_FORM_ENDPOINT: "https://api.hsforms.com/submissions/v3/integration/submit/:portalId/:formGuid",
    },
    ROUTES: {
        POLICY: {
            URL: "/policy",
            CID: "policiesLegislation",
        },
        GEOGRAPHIC_TRENDS: {
            URL: "/geotrends",
            CID: "geographicTrends",
        },
        DATA_EXPLORER: {
            URL: "/dataexplorer",
            CID: "dataExplorer",
        },
        PARTNERSHIPS: {
            URL: "/partners",
            CID: "partnerships",
        },
    }
};
