import React, { Fragment } from "react";
// import { demanddata } from "../../GraphComponents/GraphVariables/DemandData";
import SingleCard from "../../../../Components/Card";
// import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
// import DemandTable from './Tables/DemandTable'
import DemandHorizontalTable from './Tables/DemandHorizontalTable'
import HorizontalBarChart from '../../GraphComponents/HorizontalBarChart'
// import { demandmockdata } from "./DemandData";

export default function Demand(props) {
  //   const [graphData, setGraphData] = React.useState([]);
  //   // eslint-disable-next-line no-unused-vars
  //   const [graphStackedData, setGraphStackedData] = React.useState(
  //     []
  //   );

  //   useEffect(() => {
  //       setGraphData(props.data)
  //       setGraphStackedData(props.data);
  //   }, [props.data])

  // const addToReports = (data) => {
  //   props.addToReportPage(
  //     data,
  //   );
  // };

  const renderAllCharts = () => {
    return (
      <div>
        <div>

          <SingleCard
            width="100%"
            id="Demand Information Ad Posts by Location"
            title="Demand Information  >  Ad Posts by Location"
            definitions={[["", 'This chart shows the top 10 cities with the highest volume of ads, based on selected filters. The table shows all cities with ads.']]}
            graph={
              <HorizontalBarChart
                rotateXaxis
                data={props.data.postByLocation.slice(0, 10)}
                value="city"
                xaxis="Number of Ads"
                yaxis="Location Name"
              />
            }
            table={
              <DemandHorizontalTable
                data={props.data.postByLocation}
                reverseOrder={true}
                variableName='city'
                name="Demand Information Ad Posts by Location"
                id="Demand Information Ad Posts by Location"
              />}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
            id="Demand Information Ad Posts by Location and Population"
            title="Demand Information  >  Ad Posts by Location and Population"
            definitions={[["", 'This chart shows the top 10 cities by population with the highest volume of ads, based on selected filters. The table shows all cities with ads.']]}
            graph={
              <HorizontalBarChart
                data={props.data.postByLocationPop.slice(0, 10)}
                value="city"
                xaxis="Number of Ads per 100,000 population"
                yaxis="Location Name"
              />
            }
            table={
              <DemandHorizontalTable
                data={props.data.postByLocationPop}
                reverseOrder={true}
                variableName='city'
                name="Demand Information Ad Posts by Location and Population"
                id="Demand Information Ad Posts by Location and Population"
              />
            }
            addToReports={props.addToReportPage}
          />

          {/* <SingleCard
              width="100%"
              id="Demand Information Provider Age"
              title="Demand Information  >  Provider Age"
              definitions={[["Provider Age", "Provider Age is entered by the individual posting the ad, and may not represent the individual’s actual age. For example, a 17-year-old may post 21 as the age."]]}
              graph={
              <SingleBarGraph
                  data={props.data.providerAge ? props.data.providerAge : demandmockdata.providerAge}
                  keys={demanddata.providerAge}
                  xaxis="Age"
                  yaxis="Number of Individuals"
                  type="cc"
              />
              }
              table={<DemandTable data={props.data.providerAge} keys={demanddata.providerAge} id="Provider Age" single={true}/>}
              addToReports={props.addToReportPage}
          /> */}


        </div>
      </div>
    );
  }

  const renderSingleChart = () => {
      const charts = renderAllCharts();
      const singleCards = charts.props.children.props.children;

      const index = singleCards.findIndex((card) => card.props.id === props.chartId);
      if (index <= -1) {
          return <Fragment></Fragment>;
      }

      const elementToRender = singleCards[index];
      return <SingleCard
          {...elementToRender.props}
          title={props.chartTitle || props.title}
          description={props.chartInsight || ""}
          shouldUseNewStyles={true}
      />;
  }

  return (
      props.chartId
          ? renderSingleChart()
          : renderAllCharts()
  );
}
