/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Checkbox } from '../../../Components/Checkbox'
import {
  select,
  scaleBand,
  event,
  axisBottom,
  axisLeft,
  //axisRight,
  scaleLinear,
  // max,
  // nest,
  format,
  //line,
} from "d3";
import { only50StatesNames } from '../../../Data/only50StatesNames';
//import { KeyOffRounded } from "@mui/icons-material";

let allStates = [...only50StatesNames].filter(s => s.name !== 'All States')


export const GroupedBarVariableLegend = (props) => {
  let legendKeys = props.report ? [props?.activeCategory?.[1]] : allStates

  var data;
  if (props.data) {
    data = props.data;
  } else {
    data = [];
  }
  // console.log(data)

  // eslint-disable-next-line no-unused-vars
  const [keys, setKeys] = useState(props.multipleYMaxValues);
  const [states, setStates] = useState(['U.S.', 'TX']);
  const [fullStateKey, setFullStateKey] = useState([{ name: 'U.S.', stateLocation: 'U.S.' }, { name: 'Texas', stateLocation: 'TX' }]);


  //If a trendline, adding values to state
  // const [trendLine, setTrendLine] = useState(true);
  // const [trendLineLegend, setTrendLineLegend] = useState(true);
  // const [percentLine, setPercentLine] = useState(true);
  // const [percentLineLegend, setPercentLineLegend] = useState(true);

  const colors = {};
  for (let i = 0; i < props.keys.length; i++) {
    colors[props.keys[i].variable] = props.keys[i].color;
  }

  const svgRef = useRef();
  const wrapperRef = useRef();

  const handleFixedBar = (e, state) => {
    if (states[0] === state) {
      let beArray = [{ name: '', stateLocation: '' }, fullStateKey[1]]
      setStates(prevItems => prevItems.map((item, i) =>
        i === 0 ? '' : item
      ));
      props.handleStateClick(0, { name: '', stateLocation: '' }, beArray)
    } else {
      // let newStates = [state, states[1]]
      let beArray = [{ name: state, stateLocation: state }, fullStateKey[1]]
      setStates(prevItems => prevItems.map((item, i) =>
        i === 0 ? state : item
      ));
      setFullStateKey(prevItems => prevItems.map((item, i) =>
        i === 0 ? {name: state, stateLocation: state} : item
      ));
      props.handleStateClick(0, { name: state, stateLocation: state }, beArray)
    }
  }

  const handleLegendClick = (e, key) => {
    if (states[1] === key.stateLocation) {
      let beArray = [fullStateKey[0], { name: '', stateLocation: '' }]
      setStates(prevItems => prevItems.map((item, i) =>
        i === 1 ? '' : item
      ));
      props.handleStateClick(1, { name: '', stateLocation: '' }, beArray)
    } else {
      // let newStates = [states[0], key.stateLocation]
      let beArray = [fullStateKey[0], key]
      setStates(prevItems => prevItems.map((item, i) =>
        i === 1 ? key.stateLocation : item
      ));
      setFullStateKey(prevItems => prevItems.map((item, i) =>
        i === 1 ? key : item
      ));
      props.handleStateClick(1, key, beArray)
    }
  };


  useEffect(() => {
    setFullStateKey(props.statePolicyStateSelection)
    setStates(props.statePolicyStateSelection?.map((item) => item.stateLocation))
  }, [props.statePolicyStateSelection]);


  useEffect(() => {
    function endTooltip() {
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Selecting the container that will hold the D3 graph
    const svg = select(svgRef.current);

    //Removing the previous lines and data, in order to re-draw them when the filters are changed
    svg.selectAll(".rect").remove();
    svg.selectAll(".group").remove();
    svg.selectAll(".group-label").remove();
    svg.selectAll(".axis").remove();
    svg.selectAll(".category-label").remove();
    svg.selectAll(".line").remove();
    svg.selectAll(".dots").remove();
    svg.selectAll(".myCircle").remove();
    svg.selectAll(".axislabel").remove();
    svg.selectAll(".x-axis").remove();

    // Setting the height and width of the graph (responsive to viewport size)
    var margin = { top: 30, right: 0, bottom: 90, left: 80 };
    var width;
    var height;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
      height = 500;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
      height = 500;
    } else {
      width = 1200;
      height = 500;
    }

    if (props.percentLine) {
      width = 850;
    }

    let barsWidth = 45

    if (props.report) {
      width = 700;
      barsWidth = 34
    }

    //Creating the hover tooltip
    var tooltip = select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "text1");
    tooltip.append("div").attr("class", "text2");
    tooltip.append("div").attr("class", "text3");
    tooltip.append("div").attr("class", "text4");

    // Creating all of the axis calculations
    var x_groups = scaleBand()
      .range([margin.left, width - margin.right])
      .padding(0.1);

    var x_values = scaleBand();

    var y = scaleLinear().range([height - margin.bottom, margin.top]);

    var groups_axis = axisBottom(x_groups);

    // Creating the data structure for grouped bars
    var nested = data.length > 0 ? data.map((item, value) => {
      return (
        {
          key: item.xaxis,
          value: [
            {
              stateLo: 'U.S.',
              displayName: 'All States',
              key: `${item.variable}1`,
              category: item.xaxis,
              value: item.value[0] === 'Life' ? 100 : Number(item.value[0]),
              percent: null,
            },
            {
              stateLo: item.stateLo,
              displayName: item.stateLo,
              key: `${item.variable}2`,
              category: item.xaxis,
              value: item.value[1] === 'Life' ? 100 : Number(item.value[1]),
              percent: item.value[1] === 'Life' ? 'N/A' : `${item.percent}%`
            }
          ]
        }
      )
    }) : [];

    x_groups.domain(
      nested.map(function (d) {
        return d.key;
      })
    );

    x_values.domain(props.multipleYMaxValues).rangeRound([0, x_groups.bandwidth()]);

    var yMaxValue = 100;
    // if (trendLine && props.line) {
    //   yMaxValue = max(props.line, (d) => d.irp_youthactivecare);
    // } else if (props.yaxisMaxValue) {
    //   yMaxValue = max(nested, function (d) {
    //     return max(d.values, function (d) {
    //       return max(d.value, function (d) {
    //         return d.value;
    //       });
    //     });
    //   });
    // } else if (props.multipleYMaxValues) {
    //   let eachBarValue = []
    //   data.forEach(obj => {
    //     props.multipleYMaxValues.forEach(variable => {
    //       return eachBarValue.push(Number(obj[variable]))
    //     })
    //   })
    //   yMaxValue = max(eachBarValue)
    // } else {
    //   yMaxValue = max(data, (d) => Number(d.total));
    // }

    if (!yMaxValue) {
      yMaxValue = 0;
    }

    y.domain([0, yMaxValue]);

    var ticks = y.ticks(),
      lastTick = ticks[ticks.length - 1],
      newLastTick = lastTick + (ticks[1] - ticks[0]);
    ticks.push(newLastTick);

    y.domain([0, newLastTick]);

    var formatxAxis = format(".0f");
    var yAxis;

    if (yMaxValue < 20) {
      yAxis = axisLeft(y).ticks(yMaxValue).tickFormat(formatxAxis);
    } else {
      yAxis = axisLeft(y).tickValues(ticks);
    }

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + (height - 75) + ")")
      .call(groups_axis)
      .selectAll(".tick text")
      .call(wrap, 40);

    // wrap x axis labels function
    function wrap(text, width) {
      text.each(function () {
        var text = select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = 0.5, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    svg
      .append("g")
      .attr("class", "y axis")
      .attr("transform", `translate(${80}, 0 )`)
      .call(yAxis);

    var groups_g = svg
      .selectAll(".group")
      .data(nested)
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "group group-" + d.key;
      })
      .attr("transform", function (d) {
        return "translate(" + x_groups(d.key) + ",0)";
      });

    var values_g = groups_g
      .selectAll(".value")
      .data(function (d) {
        return d.value;
      })
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "value value-" + d.key;
      })
      .attr("transform", function (d) {
        //return "translate(" + x_values(d.key) + ",0)";
        const index = props.multipleYMaxValues.findIndex((searchValue, i) => d.key === searchValue);
        return `translate(${index % 2 === 0 ? 0 : barsWidth},0)`; // 0 : 50
      });


    // eslint-disable-next-line no-unused-vars
    var rects = values_g
      .selectAll(".rect")
      .data(function (d) {
        return [d];
      })
      .enter()
      .append("rect")
      .attr("class", "rect")
      .attr("width", barsWidth) // 50
      // .attr("width", x_values.bandwidth())
      .attr("x", function (d) {
        return 0;
      })
      .attr("y", function (d) {
        return y(d.value);
      })
      .attr("height", function (d) {
        if (d.value === 0) {
          return 0;
        } else {
          return height - y(d.value) - margin.bottom;
        }
      })
      .style("fill", function (d) {
        return colors[d.stateLo];
      })
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove);

    //Hover for bar graph
    function onMouseOver(d) {
      tooltip.style("opacity", 0);
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      tooltip.select(".text1").text(d.displayName);
      tooltip.select(".text2").text(d.category);
      tooltip.select(".text3").text(`Total: ${d.value === 100 ? 'Life' : d.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
      tooltip.select(".text4").text(d.percent ? `Percent: ${d.percent}` : null);
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", "-1");
    }

    function onMouseMove(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      tooltip
        .style("top", event.clientY - 120 + "px")
        .style("left", event.clientX - 150 + "px");
    }

    // **************** TrendLine **************

    if (props.trendLine) {
      var lineData;
      if (props.line) {
        // eslint-disable-next-line
        lineData = props.line.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      } else {
        // eslint-disable-next-line
        lineData = data.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      }
    }

    // text label for the x axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr(
        "transform",
        "translate(" + (width + 60) / 2 + " ," + (height) + ")"
      )
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);
  }, [
    keys,
    props.yaxisMaxValue,
    props.multipleYMaxValues,
    //props.trendLineLegend,
    props.statePolicyStateSelection,
    props.report,
    //trendLine,
    //props.trendLine,
    allStates,
    colors,
    props.keys,
    props.xaxis,
    props.yaxis,
    data,
    //props.percentLine,
    //percentLine,
    //props.percentLegend,
    //props.line,
    //props.lineTitle,
  ]);

  return (
    <div
      className={props.smalltext ? "graph-stacked" : "graph"}
      id={props.id}
      style={{ position: "relative", height: 550 }}
    >
      <div ref={wrapperRef} style={{ position: "relative", height: 510 }}>
        <svg ref={svgRef} style={{ width: "100%", height: 510 }}></svg>
      </div>
      <div className="fields" style={{ display: "flex", flexWrap: "wrap", marginTop: 15 }}>
        <Checkbox
          text={'All States'}
          checked={states?.[0] === 'U.S.'}
          onClickFunc={handleFixedBar}
          onClickFuncItem='U.S.'
          // onClickFuncExtra={ }
          // onClickFuncItemExtra={ }
          color={colors['U.S.']}
          report={props.report}
        />
      
        {legendKeys.map((stateObj, index) => (
          <Checkbox
            key={stateObj.stateLocation}
            text={stateObj.stateLocation}
            checked={states?.[1] === stateObj.stateLocation}
            onClickFunc={handleLegendClick}
            onClickFuncItem={stateObj}
            // onClickFuncExtra={ }
            // onClickFuncItemExtra={ }
            color={colors[stateObj.stateLocation]}
            report={props.report}
          />
        ))}
      </div>
    </div>
  );
};
