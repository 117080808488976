import React, { useEffect, useState, Fragment } from "react";
import { API } from "aws-amplify";
import uniq from "lodash/uniq";
import throttle from "lodash/throttle";

import Layout from "./Layout";
import LoginCTABlock from "../../Components/PublicSite/CTABlock/LoginCTABlock";
import Hero from "../../Components/PublicSite/Hero/Hero";
import ChartWrapper from "../../Components/PublicSite/ChartWrapper/ChartWrapper";
import LearnAboutDropdown from "../../Components/PublicSite/LearnAboutDropdown/LearnAboutDropdown";
import useContentful from "../../Lib/Hooks/useContentful";
import { getMapOrChartData, getAPIMethodByMapChartName } from "../../Lib/Helpers/MapChartHelpers";
import { useAppContext } from "../../Lib/UserContext";
import { ENUMS } from "../../Lib/Enums";

export default function DataExplorer() {
  const { stateLocation } = useAppContext();
  const { getSiteContent, getChartContent } = useContentful();
  const [siteContent, setSiteContent] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [listOfCharts, setListOfCharts] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const getSiteHeaderTitle = (header) => {
    if (!header) {
      return (<Fragment></Fragment>)
    }

    const headerElementStr = header
      .replace("\\", "<span class='site-header-title title highlight'>")
      .replace("\\", "</span>");
    return (
      <div
        className="site-header-title title"
        dangerouslySetInnerHTML={{ __html: headerElementStr }}
      >
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const response = await getSiteContent(ENUMS.ROUTES.DATA_EXPLORER.CID);
      setSiteContent(response);
      setSelectedCategory(response.selection);

      const menuItems = response.selectionText.split(", ").map((item, key) => ({
        text: item,
        value: item
      }));
      setDropdownOptions(menuItems);
    })();
  }, [getSiteContent]);

  useEffect(() => {
    (async () => {
      const response = await getChartContent(ENUMS.ROUTES.DATA_EXPLORER.CID);
      const charts = response.filter(({ chartSelectionCategory }) =>
        chartSelectionCategory === selectedCategory
      );

      setListOfCharts(charts);
    })();
  }, [getChartContent, selectedCategory]);

  const callAPI = (method, defaultFilterArray, data, apiMethodToCategory) => {
    API.post("lighthouse-data-initiative", method, {
      body: {
        filters: defaultFilterArray,
        stateLocation
      }
    }).then((response) => {
      data[apiMethodToCategory[method]] = response;
      setChartData(data);
    }).catch((error) => {
      console.log("Error on api call", error);
    });
  };

  const throttleCallAPI = throttle(callAPI, 1000);

  useEffect(() => {
    const { defaultFilterArray } = getMapOrChartData(stateLocation);
    const apiMethodToCategory = {};
    const data = {};

    const apiMethods = uniq(listOfCharts.map((chart) => {
      const methodName = getAPIMethodByMapChartName(chart.chartCategory, false, stateLocation);
      apiMethodToCategory[methodName] = chart.chartCategory;
      return methodName;
    }));

    apiMethods.forEach((method) => {
      throttleCallAPI(method, defaultFilterArray, data, apiMethodToCategory);
    });
    // eslint-disable-next-line
  }, [listOfCharts]);

  return (
    <Layout>
      <div className="data-explorer">
        <Hero
          title={getSiteHeaderTitle(siteContent.header)}
          body={siteContent.headerBody}
          button={siteContent.headerButton}
          buttonLink={siteContent.headerButtonLink}
        />
        <LearnAboutDropdown
          menuItems={dropdownOptions}
          selectedValue={selectedCategory}
          setSelectedValue={setSelectedCategory}
        />
        <div className="site-body">
          <div className="site-section">
            <div className="site-header">
              {getSiteHeaderTitle(siteContent.chartTitle)}
            </div>
            <div className="site-section-body">
              {siteContent.chartBody}
            </div>
          </div>
          {
            listOfCharts.map((chart, key) => (
              <ChartWrapper
                key={key}
                chartData={chartData}
                chartCategory={chart.chartCategory}
                chartId={chart.chartId}
                chartSource={chart.chartSource}
                chartTitle={chart.chartTitle}
                chartInsight={chart.chartInsight}
              />
            ))
          }
        </div>
        <div className="site-section">
          <LoginCTABlock
            ctaTitle={siteContent.ctaTitle}
            ctaBody={siteContent.ctaBody}
            ctaButton={siteContent.ctaButton}
            ctaButtonLink={siteContent.ctaButtonLink}
          />
        </div>
      </div>
    </Layout>
  );
}
